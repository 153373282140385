













import Vue from "vue";
import { ConfigWithTitle } from "@/store";

export default Vue.extend({
  name: "PuzzleSelectionContainer",

  computed: {
    configs(): ConfigWithTitle[] {
      return this.$store.getters.knownConfigs;
    }
  }
});
