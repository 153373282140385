import Vue from "vue";
import Vuex from "vuex";
import { Config, State } from "@/store/State";
import sampleSize from "lodash/sampleSize";
import range from "lodash/range";

Vue.use(Vuex);

const MAX_ROWS = 5;

export type ConfigWithTitle = {
  configId: string;
  title: string;
};

export default new Vuex.Store<State>({
  state: {
    configs: {},
    currentConfig: "",
    sample: []
  },

  mutations: {
    SET_CONFIG(state, configs: Record<string, Config>) {
      state.configs = { ...configs };
    },

    CREATE_SAMPLE(state, puzzleId: string) {
      state.currentConfig = puzzleId;
      const indexes = range(state.configs[state.currentConfig].entries.length);
      state.sample = sampleSize(indexes, MAX_ROWS);
    }
  },

  actions: {
    async loadConfig({ commit }): Promise<void> {
      const configs = await fetch("./config.json").then(resp => resp.json());
      commit("SET_CONFIG", configs);
    }
  },

  getters: {
    isConfigReady(state): boolean {
      return Object.keys(state.configs).length > 0;
    },

    currentConfig({ configs, currentConfig }): Config | undefined {
      return configs[currentConfig];
    },

    knownConfigIds({ configs }): string[] {
      return Object.keys(configs);
    },

    knownConfigs({ configs }): ConfigWithTitle[] {
      return Object.keys(configs).map(id => ({
        configId: id,
        title: configs[id].title
      }));
    },

    title(state, getters): string {
      return getters.currentConfig?.title || "";
    },

    columns(state, getters): string[] {
      return getters.currentConfig?.columns || [];
    },

    entries(state, getters): string[][] {
      return state.sample.map(
        index => getters.currentConfig?.entries[index] || []
      );
    }
  }
});
