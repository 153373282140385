









































import Vue, { PropType } from "vue";
import PuzzleItem from "@/components/PuzzleItem.vue";

interface NullSafeDragEvent extends DragEvent {
  dataTransfer: DataTransfer;
}

export type Row = {
  columns: string[][];
  solved: boolean;
};

function getNumber(event: NullSafeDragEvent, type: string): number {
  return parseInt(event.dataTransfer.getData(type), 10);
}

export default Vue.extend({
  name: "Puzzle",

  components: { PuzzleItem },

  props: {
    title: { type: String, required: true },
    columns: { type: Array as PropType<string[]>, required: true },
    rows: { type: Array as PropType<Row[]>, required: true },
    reorder: {
      type: Function as PropType<
        (column: number, from: number, to: number) => void
      >,
      required: true
    },
    reset: {
      type: Function as PropType<() => void>,
      required: true
    }
  },

  computed: {
    allSolved(): boolean {
      return this.rows.length > 0 && this.rows.every(row => row.solved);
    }
  },

  methods: {
    dragStart(
      event: NullSafeDragEvent,
      columnIndex: string,
      fromIndex: string
    ): void {
      event.dataTransfer.setData("column", columnIndex);
      event.dataTransfer.setData("fromIndex", fromIndex);
      event.dataTransfer.effectAllowed = "move";
    },

    drop(event: NullSafeDragEvent, columnIndex: number, toIndex: number) {
      const eventColumnIndex = getNumber(event, "column");
      const fromIndex = getNumber(event, "fromIndex");

      if (columnIndex !== eventColumnIndex) {
        // Don't drag stuff between columns!
        return;
      }

      if (this.rows[toIndex].solved) {
        // Don't drag stuff onto solved rows!
        return;
      }

      this.reorder(columnIndex, fromIndex, toIndex);
    }
  }
});
