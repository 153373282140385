












import Vue from "vue";
import Puzzle, { Row } from "@/components/Puzzle.vue";
import Spinner from "@/components/Spinner.vue";
import range from "lodash/range";
import shuffle from "lodash/shuffle";

function transpose(array: number[][]): number[][] {
  return array.reduce(
    (prev, next) => next.map((item, i) => (prev[i] || []).concat(next[i])),
    [] as number[][]
  );
}

function doShuffle(entryRange: number[]): number[][] {
  return [
    shuffle(entryRange),
    shuffle(entryRange),
    shuffle(entryRange),
    shuffle(entryRange)
  ];
}

function hasSolved(indices: number[][]): boolean {
  return indices.some(i => new Set(i).size === 1);
}

export default Vue.extend({
  name: "PuzzleContainer",

  props: {
    puzzleId: { type: String, required: true }
  },

  components: { Puzzle, Spinner },

  mounted() {
    this.reset();
  },

  data(): { indices: number[][] } {
    return {
      indices: []
    };
  },

  computed: {
    configReady(): boolean {
      return this.$store.getters.isConfigReady;
    },
    columns(): string[4] {
      return this.$store.getters.columns;
    },
    entries(): string[][][] {
      return this.$store.getters.entries;
    },
    rows(): Row[] {
      return this.indices.map(indices => {
        const columns = indices.map((row, column) => this.entries[row][column]);
        const solved = new Set(indices).size === 1;
        return { columns, solved };
      });
    },
    title(): string {
      return this.$store.getters.title;
    }
  },

  methods: {
    shuffle(allowSolved = false) {
      const entryCount = this.entries.length;
      const entryRange = range(entryCount);
      let indicesByColumn: number[][];

      do {
        indicesByColumn = doShuffle(entryRange);
        this.indices = transpose(indicesByColumn);
      } while (hasSolved(this.indices) && !allowSolved);
    },

    reset() {
      this.indices = [];
      this.$store.commit("CREATE_SAMPLE", this.puzzleId);
      this.shuffle();
    },

    reorder(column: number, from: number, to: number): void {
      const fromEntry = [...this.indices[from]];
      const toEntry = [...this.indices[to]];
      const moved = fromEntry[column];
      fromEntry[column] = toEntry[column];
      toEntry[column] = moved;

      // need to tickle vue's reactivity system so that the changes will get picked up
      Vue.set(this.indices, from, fromEntry);
      Vue.set(this.indices, to, toEntry);
    }
  }
});
