




import Vue from "vue";
import PuzzleSelectionContainer from "@/components/PuzzleSelectionContainer.vue";

export default Vue.extend({
  name: "PuzzleSelectionView",
  components: { PuzzleSelectionContainer }
});
