import Vue from "vue";
import VueRouter, { NavigationGuard, RouteConfig } from "vue-router";
import PuzzleSelectionView from "@/views/PuzzleSelectionView.vue";
import PuzzleView from "@/views/PuzzleView.vue";
import ErrorView from "@/views/ErrorView.vue";
import { State } from "@/store/State";
import { Store } from "vuex";

Vue.use(VueRouter);

function getEnsureConfig(store: Store<State>): NavigationGuard {
  return (to, from, next) => {
    if (store.getters.isConfigReady) {
      return next();
    }

    return store
      .dispatch("loadConfig")
      .then(next)
      .catch(e => {
        // eslint-disable-next-line no-console
        console.error("Could not load config", e);
        next({ name: "error" });
      });
  };
}

function getRoutes(store: Store<State>): RouteConfig[] {
  const ensureConfig: NavigationGuard = getEnsureConfig(store);

  return [
    {
      path: "/",
      name: "puzzleSelection",
      component: PuzzleSelectionView,
      beforeEnter: ensureConfig
    },

    {
      path: "/puzzle/:id",
      name: "puzzle",
      component: PuzzleView,
      beforeEnter: (to, from, next) => {
        ensureConfig(to, from, nextParam => {
          // ensureConfig "returned" something
          if (nextParam) {
            return next(nextParam);
          }

          const { id } = to.params;

          if (store.getters.knownConfigIds.includes(id)) {
            // all is well: config is loaded and contains the desired id
            next();
          } else {
            // eslint-disable-next-line no-console
            console.error("Invalid puzzle ID given in route");
            next({ name: "error" });
          }
        });
      },
      props: true
    },

    {
      path: "/error/:puzzleId?",
      name: "error",
      component: ErrorView,
      props: true
    },

    { path: "*", redirect: { name: "puzzleSelection" } }
  ];
}

export function getRouter(store: Store<State>): VueRouter {
  const routes = getRoutes(store);
  return new VueRouter({ routes });
}
