




import Vue from "vue";
import PuzzleContainer from "@/components/PuzzleContainer.vue";

export default Vue.extend({
  name: "PuzzleView",
  components: { PuzzleContainer },
  props: { id: { type: String, required: true } }
});
