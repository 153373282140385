













import Vue from "vue";

export default Vue.extend({
  name: "ErrorView",

  props: {
    puzzleId: { type: String, default: "" }
  },

  methods: {
    reload() {
      if (this.puzzleId) {
        this.$router
          .push({ name: "puzzle", params: { id: this.puzzleId } })
          .catch(error => {
            // eslint-disable-next-line no-console
            console.error(
              "Could not reload the puzzle, switching to the selection view.",
              error
            );
            this.$router.push({ name: "puzzleSelection" });
          });
      } else {
        this.$router.push({ name: "puzzleSelection" });
      }
    }
  }
});
