







import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "PuzzleItem",

  props: {
    items: { type: Array as PropType<string[]>, required: true }
  },

  computed: {
    isSingleItem(): boolean {
      return this.items.length === 1;
    }
  }
});
